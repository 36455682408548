<template>
    <div class="address">
        <div class="tips">
            <div class="tips-title flex">
                温馨提示
            </div>
            <div class="tips-box">
                您的订单将由海外直运，为了确保清关顺利，请<strong>保证支付人信息填写正确</strong>下单即可生成清关信息，因此无法在下单后修改地址。
            </div>
        </div>

        <div class="form">
            <div class="form-item">
                <div class="title">收件人信息</div>
                <van-form ref="form">
                    <van-field
                            v-model="data.name"
                            name="用户名"
                            label="姓名"
                            :rules="[{ required: true,validator:nameValidator,message: '请填写正确的姓名' }]"/>
                    <van-field
                            v-model="data.phone"
                            name="手机号"
                            label="手机号"
                            :rules="[{required: true,validator: phoneValidator,message:'请填写正确的手机号'}]"/>
                    <van-field
                            readonly
                            clickable
                            name="area"
                            :value="data.address"
                            label="所在地区"
                            placeholder="省 / 市 / 区 / 街道"
                            @click="showArea = true"/>
                    <van-field
                            v-model="data.detailRess"
                            name="area"
                            label="详细地址"
                            :rules="[{ required: true,validator: addRessValidator, message: '请填写详细地址' }]"/>
                    <div class="hr" style="padding-bottom: 8px"></div>
                </van-form>
            </div>

            <div class="form-item">
                <div class="title">支付人信息</div>
                <van-form ref="form2">
                    <van-field
                            v-model="data.payName"
                            name="用户名"
                            label="姓名"
                            :rules="[{ required: true,validator:nameValidator, message: '请填写用户名' }]"/>
                    <van-field
                            v-model="data.idNumber"
                            name="身份证号"
                            label="身份证号"
                            :rules="[{required: true,validator: (val) => validIdentity(val),message:'请填写正确的身份证'}]"/>
                    <div class="hr" style="padding-bottom: 8px"></div>
                </van-form>
            </div>

            <div class="btn" @click="onSubmit">确认</div>
        </div>


        <van-popup v-model="showArea" position="bottom">
            <van-area
                    :area-list="areaList"
                    @confirm="onConfirm"
                    @cancel="showArea = false"
            />
        </van-popup>

        <!--确认弹出框-->
        <van-popup v-model="showDetail">
            <div class="popup">
                <div class="title">
                    您的营养包将邮寄至以下地址，请确认信息正确
                </div>
                <div class="confirm-box">
                    <div class="confirm-title flex">
                        收货信息
                    </div>
                    <div class="confirm-text">
                        <div>
                            <span style="margin-right: 22px">{{ data.name }}</span>
                            <span>{{ data.phone.slice(0, 3) }} {{ data.phone.slice(3, 7) }} {{
                                    data.phone.slice(7, 11)
                                }}</span>
                        </div>
                        <div>
                            <span>{{ data.address }}</span>
                        </div>
                        <div>
                            <span>{{ data.detailRess }}</span>
                        </div>
                    </div>
                </div>
                <div class="confirm-box">
                    <div class="confirm-title flex">
                        支付人信息
                    </div>
                    <div class="confirm-text">
                        <div>
                            <span>{{ data.payName }}</span>
                        </div>
                        <div>
                            <span>{{ data.idNumber }}</span>
                        </div>
                    </div>
                </div>
                <div class="btn-list flex">
                    <div class="btn-item" @click="showDetail=false">取消</div>
                    <div class="btn-item" @click="createAddress">确定</div>
                </div>
            </div>
        </van-popup>
    </div>
</template>

<script>
    import {areaList, validIdentity} from "@/until/index"
    import {Toast} from "vant";

    export default {
        name: "",
        data() {
            return {
                areaList,
                showArea: false,
                showDetail: false,
                data: {
                    name: '',
                    phone: '',
                    address: '',
                    detailRess: '',
                    payName: '',
                    idNumber: '',
                }
            }
        },
        created() {
            const order_cn = this.$route.params.sn
            this.$request.get(
                "order_detail", {
                    order_sn: order_cn
                }
            ).then(res => {
                if(res.code == 1){
                    if(res.data?.consignee_info?.name){
                        this.$router.push({
                            path: `/product/show/${order_cn}`
                        })
                    }
                }
                console.log(res)
            })
        },
        methods: {
            validIdentity,
            onSubmit() {
                let res1 = this.$refs.form.validate()
                let res2 = this.$refs.form2.validate()
                Promise.all([
                    res1,
                    res2
                ]).then(res => {
                    this.showDetail = true
                })
            },
            //确认地址
            createAddress() {
                let toast = Toast.loading({
                    message: '提交中...',
                    forbidClick: true,
                })
                const order_cn = this.$route.params.sn
                let addressList = this.data.address.split("/")

                this.$request.post("consignee", {
                    order_sn: order_cn,
                    consignee_name: this.data.name,
                    phone: this.data.phone,
                    consignee_province: addressList[0],
                    consignee_city: addressList[1],
                    consignee_area: addressList[2],
                    consignee_address: this.data.detailRess,
                    pay_name: this.data.payName,
                    id_no: this.data.idNumber
                }).then(res => {
                    toast.clear()
                    let sn = this.$route.params.sn
                    if (res.code == 1) {
                        this.$router.push({
                            path: `/product/show/${sn}`
                        })
                    } else {
                        Toast(res.message)
                    }
                })
            },
            phoneValidator(val) {
                let patt2 = /^1[3|4|5|8]\d{9}$/
                return patt2.test(val)
            },
            nameValidator(val) {
                let patt = /^[a-zA-z\u4e00-\u9fa5\s]{2,11}$/
                return patt.test(val)
            },
            addRessValidator(val) {
                return val.length < 100
            },
            onConfirm(values) {
                this.data.address = values
                    .filter((item) => !!item)
                    .map((item) => item.name)
                    .join('/');

                this.showArea = false;
            },
        }
    }
</script>

<style scoped lang="less">
    .address {
        width: 100%;
        min-height: 100vh;
        background-color: #F3F3F4;
        padding: 16px 20px 22px 20px;
        box-sizing: border-box;
    }

    .tips {
        padding: 18px 20px 20px 15px;
        background-color: white;
        border-radius: 10px;
        margin-bottom: 15px;

        .tips-title {
            font-size: 14px;
            color: #292C2F;
            margin-bottom: 11px;

            &:before {
                display: block;
                content: '';
                height: 25px;
                width: 5px;
                border-radius: 14px;
                margin-right: 8px;
                background-color: #6B00AF;
            }
        }

        .tips-box {
            font-size: 12px;
            color: #666666;
            line-height: 20px;
            //font-weight: 700;
            letter-spacing: 1px;

            strong {
                color: black;
                font-weight: 900;
                font-family: HYQiHei-FZS, HYQiHei;
            }
        }
    }

    .form {
        width: 100%;

        .form-item {
            background-color: white;
            padding: 20px 20px 10px 20px;
            box-sizing: border-box;
            border-radius: 10px;
            margin-top: 15px;
        }

        .title {
            font-size: 17px;
            color: #292C2F;
            letter-spacing: 1px;
            margin-bottom: 15px;
        }

        /deep/ .van-cell {
            padding-left: 0;
            padding: 15px 0px;

            &:after {
                left: 0;
                width: 100%;
                border-bottom: 2px solid #e6e7e8;
            }
        }

        /deep/ .van-field__label {
            letter-spacing: 1px;
            color: #292C2F;
            font-size: 15px;
            width: 70px;
        }

        .btn {
            font-size: 16px;
            font-weight: 700;
            color: white;
            padding: 10px 0px;
            background-color: #9E3DF6;
            text-align: center;
            width: 80%;
            background-color: #9E3DF6;
            margin: 0 auto;
            margin-top: 20px;
            border-radius: 30px;
        }
    }

    /deep/ .van-popup {
        background-color: transparent;
    }

    /deep/ .van-picker__confirm {
        font-size: 16px;
        color: #9e3df6;
    }

    /deep/ .van-picker__cancel {
        font-size: 16px;
    }

    .popup {
        width: calc(100vw - 30px);
        border-radius: 5px;
        overflow: hidden;
        background-color: white;
        padding: 0px 15px;
        box-sizing: border-box;

        .title {
            font-size: 15px;
            color: #292C2F;
            border-bottom: 1px solid #292C2F;
            padding-top: 25px;
            padding-bottom: 3px;
            margin-bottom: 27px;
        }

        .confirm-box {
            padding: 0px 9px;
            box-sizing: border-box;
            margin-top: 16px;

            .confirm-title {
                font-size: 14px;
                margin-bottom: 9px;

                &:before {
                    height: 16px;
                    width: 3px;
                    display: block;
                    content: '';
                    background-color: #6B00AF;
                    border-radius: 5px;
                    margin-right: 3px;
                }
            }

            .confirm-text {
                font-size: 14px;
                color: #666666;
                line-height: 30px;
                padding-left: 6px;
            }
        }

        .btn-list {
            border-top: 1px solid #dfdfdf;
            margin-top: 25px;
            width: calc(100% + 30px);
            margin-left: -15px;

            .btn-item {
                flex: 1;
                padding: 12px 0px;
                font-size: 16px;
                color: #666666;
                font-weight: 600;
                text-align: center;

                &:last-child {
                    border-left: 1px solid #dfdfdf;
                    color: #6B00AF;
                }
            }
        }
    }
</style>